@mixin fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin fill-parent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
