//
// Generated from figma using CSSGen plugin and then modified
//

$font-family-sofia: "sofia-pro", sans-serif;
$font-family-halyard: "sofia-pro", sans-serif;//halyard-display, sans-serif;

$color-black: rgba(0, 0, 0, 1);

$color-white: rgba(255, 255, 255, 1);
$color-white-opacity6: rgba(255, 255, 255, 0.6);

$color-grey: rgba(128, 128, 128, 1);
$color-dark-grey: rgba(17, 17, 17, 1);

$color-dark-grey-opacity4: rgba(17, 17, 17, 0.4);
$color-dark-grey-opacity1: rgba(17, 17, 17, 0.1);

$color-jp-blue: rgba(0, 97, 255, 1); //rgba(1, 103, 255, 1); // new colour from the gif, old from figma
$color-error: rgb(192, 36, 36);

@mixin font-desktop-h-3 {
    font-size: 45.22999954223633px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 48px;
}
@mixin font-desktop-body {
    font-size: 16px;
    font-family: $font-family-halyard;
    font-weight: normal;
    line-height: 30px;
}
@mixin font-desktop-h-4 {
    font-size: 31.989999771118164px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 36px;
}
@mixin font-desktop-h-5 {
    font-size: 22.6200008392334px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 28px;
}
@mixin font-desktop-h-6 {
    font-size: 16px;
    font-family: $font-family-halyard;
    font-weight: bold;
    line-height: 24px;
}
@mixin font-desktop-h-2 {
    font-size: 63.959999084472656px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 72px;
}
@mixin font-desktop-h-2-bold {
    font-size: 63.959999084472656px;
    font-family: $font-family-halyard;
    font-weight: bold;
    line-height: 72px;
}
@mixin font-desktop-h-4-bold {
    font-size: 31.989999771118164px;
    font-family: $font-family-halyard;
    font-weight: bold;
    line-height: 36px;
}
@mixin font-desktop-h-5-bold {
    font-size: 22.6200008392334px;
    font-family: $font-family-halyard;
    font-weight: bold;
    line-height: 28px;
}
@mixin font-desktop-h-1 {
    font-size: 90.44000244140625px;
    font-family: $font-family-halyard;
    font-weight: bold;
    line-height: 80px;
}
@mixin font-desktop-halyard-h-1 {
    font-size: 90.44000244140625px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 80px;
}
@mixin font-desktop-halyard-h-2 {
    font-size: 63.959999084472656px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 72px;
}
@mixin font-desktop-halyard-h-3 {
    font-size: 45.22999954223633px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 48px;
}
@mixin font-desktop-halyard-h-4 {
    font-size: 31.989999771118164px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 36px;
}
@mixin font-desktop-halyard-h-5 {
    font-size: 22.6200008392334px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 28px;
}
@mixin font-desktop-halyard-h-6 {
    font-size: 16px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 24px;
}
@mixin font-desktop-halyard-xl {
    font-size: 127.87999725341797px;
    font-family: $font-family-halyard;
    font-weight: 700;
    line-height: 100px;
}
