@import 'figma-desktop';
@import 'mixins';
@import 'modal';

a {
    text-decoration: none;
    color: $color-jp-blue;
}

body {
    @include font-desktop-body;
    margin: 0;
    color: #111111;
    background-color: $color-jp-blue;
    width: 100vw;
    height: 100vh;
}

#root {
    @include fill-parent;
    position: fixed;
}

.background {
    object-fit: cover;
    position: absolute;
    height: 687px;
    width: 100vw;
    background-color: $color-black;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.container-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.container {
    width: calc(100% - 60px); // 30px gutter either side
    max-width: 1190px;
    height: 100%;
}

.page {
    @include fill-parent;
    background-color: $color-white;

    overflow: auto;
    max-height: 100vh;

    margin: 0px; // change this to like 10px to help debug page transitions
}

// make sure certain elemtns used inside containers don't do anything funky!
// this happens on the news page!
.container .page {
    all: revert;
}

.footer {
    height: 79px;
    background: #111111;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;

    a {
        text-decoration: none;
        color: $color-white;
    }

    a.active {
        background-color: transparent;
    }

    .social {
        position: absolute;
        left: 50%;

        .inner-container {
            position: relative;
            left: -50%;
        }

        //right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        .linkedin img,
        .instagram img,
        .facebook img {
            height: 30px;
            padding: 8px;
            object-fit: contain;
        }
    }
}

.page-header {
    height: 687px;
    color: $color-white;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    overflow: hidden;

    video {
        @include fill-parent;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 100;

        .arrows {
            margin-bottom: 10px;
        }

        .btn-left {
            margin-right: 20px;
        }

        .title {
            font-family: $font-family-halyard;
            font-style: normal;
            font-weight: 700;
            font-size: 85px;
            line-height: 80px;
            display: block;
            text-shadow: 1px 1px $color-grey;

        }

        .home {
            text-align: center;

            .title,
            .arrow-down {
                text-align: center;
            }
        }

        @media only screen and (max-width: 750px) {
            .title {
                font-size: 75px;
            }
        }

        .subtitle {
            margin-top: 30px;
            @include font-desktop-halyard-h-4;
            display: block;
        }

        .arrow-down {
            margin-top: 65px;
        }
    }
}

.page-header.with-navigation {
    height: 687px;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 10;
        margin-top: 79px;
        margin-bottom: 90px;

        .image {
            position: absolute;
        }

        .arrow-down {
            display: none;
        }
    }
}

.btn,
button {
    border-radius: 100px;
    width: 138px;
    height: 36px;
    min-height: 36px;
    padding-bottom: 4px;
    @include font-desktop-h-6;
    border: none;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

button:focus {
    outline: 0;
}

button:disabled {
    opacity: 0.5;
}

.btn-black {
    background-color: $color-dark-grey;
    color: $color-white;
}

.btn-opacity {
    background-color: $color-dark-grey-opacity4;
    color: $color-white;
}

.btn-white {
    background-color: $color-white;
    color: $color-jp-blue;
}

.btn-transparent {
    background-color: rgba(0, 0, 0, 0);
}

.btn-image {
    @extend .btn-transparent;
    display: inline-block;
    width: fit-content;
}

.btn-left {
    @extend .btn-image;

    img {
        transform: rotate(90deg);
    }
}

.btn-right {
    @extend .btn-image;

    img {
        transform: rotate(-90deg);
    }
}

.btn-down {
    @extend .btn-image;
}

.btn-x {
    @extend .btn-image;
}

input {
    margin-bottom: 31px;
    width: 307px;
    height: 46px;
    border-radius: 100px;
}

textarea {
    border-radius: 12px;
    width: 100%;
    height: calc(100% - 31px);
    margin-bottom: 31px;
}

input,
textarea {
    border: 2px solid #dddddd;
    box-sizing: border-box;
    padding-top: 9px;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 9px;
    @include font-desktop-body;
}

input.invalid,
textarea.invalid {
    border: 2px solid $color-error;
}

input:valid,
textarea:valid {
    border: 2px solid #dddddd;
}

.card-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 121px;
    margin-bottom: 40px;
}

.service_grid_module,
.case_studies_grid_module {
    margin-top: 0px;
}

.service_grid_module.title,
.case_studies_grid_module.title {
    margin-top: 100px;
    @include font-desktop-halyard-h-2;
    margin-bottom: 69px;
}

.card {
    display: block;
    max-width: 490px;
    margin-bottom: 110px;
    color: $color-white;

    .title {
        margin-bottom: 32px;
        @include font-desktop-halyard-h-4;
    }

    .description {
        margin-bottom: 51px;
    }

    button {
        @extend .btn-white;
    }
}

.hover-card {
    width: 570px;
    height: 449px;
    margin-bottom: 50px;
    //background-color: blueviolet;
    display: block;
    position: relative;
    border: 1px solid $color-dark-grey-opacity1;
    overflow: hidden;
    cursor: pointer;

    .overlay {
        display: flex;
        align-items: flex-end;
        @include fill-parent;
        z-index: 100;
        //background-color: $color-grey;

        .title-img {
            @include fill-parent;
            z-index: 90;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 10%;

        }

        .title {
            margin-left: 50px;
            margin-bottom: 34px;
            color: $color-white;
            width: 60%;
            z-index: 100;
            @include font-desktop-halyard-h-3;
            text-shadow: 1px 1px $color-grey;
        }
    }

    .underlay {
        width: calc(100% - 100px); // 100% - padding or margins
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-left: 50px;
        padding-right: 50px;
        z-index: 0;
        //background-color: $color-grey;

        .underlay-img {
            @include fill-parent;
            z-index: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 10%;
        }

        .title {
            z-index: 10;
            margin-bottom: 15px;
            @include font-desktop-halyard-h-4;
        }

        .description {
            z-index: 10;
        }

        button {
            z-index: 10;
            @extend .btn-black;
            margin-top: 15px;
            margin-bottom: 30px;
        }
    }

    .underlay.with-underlay-img {
        .title {
            color: $color-white;
            text-shadow: 1px 1px $color-grey;
        }
    }
}

.hover-card.not-clickable {
    cursor: auto;

    .description {
        margin-bottom: 45px;
    }

    button {
        display: none;
    }
}

.fade-enter {
    opacity: 1;
}

.fade-enter-active {
    opacity: 0;
    transition: opacity 300ms;
}

.fade-enter-done {
    opacity: 0;
    display: none !important;
}

.fade-exit {
    opacity: 0;
}

.fade-exit-active {
    opacity: 1;
    transition: opacity 300ms;
}

.fade-exit-done {
    opacity: 1;
}

.next-story {
    background-color: $color-jp-blue;
    color: $color-white;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    position: relative;
    cursor: pointer;

    .before {
        @include fill-parent;
    }

    .before-image-overlay {
        content: '';
        background-color: $color-jp-blue;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0.9;
        @include fill-parent;
    }


    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 94px;
        margin-bottom: 95px;
        z-index: 100;

        .title {
            margin-bottom: 15px;
            @include font-desktop-halyard-h-6;
        }

        .subtitle {
            margin-bottom: 15px;
            @include font-desktop-halyard-h-4;

            img {
                transform: rotate(-90deg);
                margin-left: 30px;
            }
        }

        button {
            @extend .btn-opacity;
        }
    }
}

.what-we-do {
    background-color: $color-jp-blue;
    color: $color-white;

    .container {
        margin-top: 114px;

        .card-grid {
            margin-top: 0px;
            margin-bottom: 0px;
            justify-content: flex-start;

            .card {
                max-width: 235px;
                margin-bottom: 56px;
                margin-right: 80px;

                .header {
                    display: flex;
                    flex-direction: row;

                    .title {
                        font-size: 30px;
                    }

                    .btn-right {
                        margin-top: 8px;
                        margin-left: 10px;
                    }
                }

                .description {
                    margin-bottom: 30px;
                }
            }

            .card:last-child {
                margin-right: 0px;
            }
        }


        >.title {
            @include font-desktop-halyard-h-2;
            margin-bottom: 69px;
        }
    }
}

.what-we-do.full {
    .container {
        margin-top: 114px;
        margin-bottom: 127px;

        .card-grid {
            margin-bottom: 0px;
            justify-content: space-between;

            .card {
                max-width: 490px;
                margin-right: 0px;

                .btn-right {
                    display: none;
                }
            }
        }

        >.title {
            @include font-desktop-halyard-h-2;
            margin-bottom: 69px;
        }
    }
}

//
// Page Builder Modules
//

.subscribe_module {
    background-color: $color-jp-blue;
    
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 24px 33px;

       
        .title {
            text-transform: uppercase;
            @include font-desktop-halyard-h-6;
            flex: 1;
            color: $color-white;
            font-size: 55px;
            font-weight: 100;
            line-height: 38px;
        }

        form {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 29px;

            input {
                margin: 0;
                background-color: transparent;
                border: none;
                border-radius: 0;
                border-bottom: 1px solid #F8F8F8;
                color: $color-white;
                font-size: 20px;
                font-family: $font-family-sofia;
                font-weight: 200;
                line-height: 23px;
                padding-left: 0;
                padding-right: 0px;

                &::placeholder {
                    opacity: 0.4;
                    color: $color-white;
                }

                &:focus, &:focus-visible {
                    outline: 0;
                }
            }

            button {
                font-family: $font-family-sofia;
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                width: 159px;
                height: 42px;
                border-radius: 20px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .subscribe_module {
        .container {
            flex-direction: column;
            gap: 31px;
            padding-top: 29px;
            padding-bottom: 53px;

            .title {
                font-size: 37px;
                min-height: 61px;
                display: flex;
                align-items: center;
            }

            form {
                flex-direction: column;

                input {
                    text-align: center;
                    width: 212px;
                }

                button {
                    width: 212px;
                }
            }
        }
    }
}


.callout_module {
    padding-top: 94px;
    padding-bottom: 95px;
    color: $color-white;

    background-color: $color-jp-blue;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;

        .title {
            @include font-desktop-halyard-h-6;
            margin-bottom: 15px;
            text-align: center;
        }

        .subtitle {
            @include font-desktop-halyard-h-4;
            margin-bottom: 15px;
            text-align: center;
        }
    }
}

.two_column_module {
    .container {
        margin-top: 110px;
        margin-bottom: 120px;

        @extend .row;
        //flex-wrap: wrap;

        div {
            flex: 1;
            max-width: 490px;
            margin-right: 104px;
        }
    }
}

.one_column_module {
    .container {
        margin-top: 110px;
        margin-bottom: 120px;

        div {
            max-width: 590px;
            //@include font-desktop-h-4;
        }

        a {
            margin-top: 40px;
        }
    }
}

.one_column_module.narrow {
    .container {
        div {
            max-width: 385px;
        }
    }
}

.single_content_module {
    .container {
        margin-top: 110px;
        margin-bottom: 120px;

        a {
            margin-top: 40px;
        }
    }
}

.margin-bottom-small {
    .container {
        margin-bottom: 20px;
    }
}

.margin-bottom-none {
    .container {
        margin-bottom: 0px;
    }
}

.margin-top-small {
    .container {
        margin-top: 20px;
    }
}

.margin-top-none {
    .container {
        margin-top: 0px;
    }
}

.content-centered {
    .container {
        text-align: center;

        a,
        div {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.video_and_content_module,
.image_and_content_module {
    .container {
        margin-top: 40px;
        margin-bottom: 50px;

        display: flex;
        flex-direction: row-reverse;

        .video-col,
        .image-col {
            flex: 1;
        }

        .content-col {
            flex: 1;
            margin-left: 20px;
            margin-right: 0px;
        }

        video,
        img {
            max-width: 100%; //unquote('min(290px, 100%)');
        }

        a {
            margin-top: 40px;
        }
    }
}

.video_and_content_module.right,
.image_and_content_module.right {
    .container {
        flex-direction: row;

        .content-col {
            flex: 1;
            margin-left: 0px;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: 750px) {

    .video_and_content_module.right,
    .video_and_content_module {
        .container {
            flex-direction: column;
        }
    }
}

.fullwidth_image_module {
    margin-top: 40px;
    margin-bottom: 50px;
}

.image_module {
    .container {
        margin-top: 40px;
        margin-bottom: 50px;

        img {
            max-width: 100%; //unquote('min(290px, 100%)');
        }
    }
}

.team_members_grid_module {
    .card-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 0px;
        margin-bottom: 121px;
    }

    .react-modal-body {
        @extend .row;
        display: flex !important;
        align-items: center;

        .btn-x-container {
            padding: 20px;
            position: absolute;
        }

        .btn-left-container {
            padding-right: 20px;
        }

        .btn-right-container {
            padding-left: 20px;
        }

        .modal-body-container {
            background-color: $color-white;
            max-width: 512px;
            max-height: unquote('min(962px, 90vh)'); // https://github.com/sass/libsass/issues/2701
            overflow-y: auto;

            .title-img {
                width: 100%;
                height: 410px;
                object-fit: cover;
                object-position: 50% 10%;
            }

            .container {
                margin-left: 30px;
                margin-right: 30px;
                margin-bottom: 0px;
                height: auto;

                .title {
                    margin-top: 47px;
                    @include font-desktop-halyard-h-4;
                    font-size: 36px;
                }

                .role {
                    margin-bottom: 29px;
                }

                .quote {
                    margin-bottom: 29px;
                    font-style: italic;
                    font-weight: bold;
                }

                .contact-container {
                    @extend .row;
                    margin-top: 35px;
                    flex-wrap: wrap;

                    a {
                        @extend .btn;
                        @extend .btn-black;
                        width: auto;
                        padding-left: 20px;
                        padding-right: 20px;
                        margin-right: 10px;
                        margin-bottom: 30px;
                    }

                    .linkedin {
                        border-radius: 5px;
                        padding: 0px;
                        margin: 0px;
                    }

                    .linkedin img,
                    .instagram img,
                    .facebook img {
                        height: 30px;
                        padding: 5px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}

.bg-dark-text-white-h4 {
    background-color: $color-dark-grey;

    .container {
        div {
            max-width: 590px;
            @include font-desktop-h-4;
            color: $color-white;
        }
    }
}

.bg-white-text-black-h3 {
    background-color: $color-white;

    .container {
        div {
            max-width: 725px;
            margin-bottom: 40px;
            @include font-desktop-h-3;
        }
    }
}

.page_header_module-with-bg-white-text-black {
    height: 293px;

    .title {
        color: #000000;
    }

    .arrow-down {
        display: none;
    }
}

.page_header_module.bg-white-text-black {
    @extend .page_header_module-with-bg-white-text-black;
}

//
// Pages
//

.landing-page {
    @extend .page;
    background-color: $color-jp-blue;
    color: $color-white;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include font-desktop-h-5;

    .logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 590px;
        height: 590px;
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: -5px;
            bottom: -5px;
            left: -5px;
            right: -5px;

        }
    }
}

.form {
    display: flex;
    flex-direction: row;
    margin-top: 22px;
    margin-bottom: 118px;

    .col-1 {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 26px;
    }

    .col-2 {
        flex: 2;
        display: flex;
        flex-direction: column;
    }

    input {
        width: 100%
    }

    .submit {
        align-self: end;
    }

    .message {
        text-align: center;
        border: 2px solid #dddddd;
        border-radius: 12px;
        padding: 50px;
        font-weight: bold;
    }
}

.contact-page {
    .page-header {
        @extend .page_header_module-with-bg-white-text-black;

        .title {
            text-shadow: none;
        }
    }

    .map {
        height: 600px;

        .container {
            width: 100%;
            max-width: 100%;
        }
    }
}

.news-feed-page {
    .page-header {
        @extend .page_header_module-with-bg-white-text-black;

        .title {
            text-shadow: none;
        }

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .sortby {
            width: 100%;
            max-width: 307px;
            margin-bottom: 0px;
        }
    }

    .news-card {
        margin-bottom: 100px;
        cursor: pointer;

        .image {
            width: 100%;
            margin-bottom: 34px;
            object-fit: cover;
            height: 600px;
        }

        .title {
            @include font-desktop-halyard-h-4;
            margin-bottom: 9px;
        }

        .tags {
            color: rgba(153, 153, 153, 1);
            margin-bottom: 30px;
        }

        .excerpt {
            margin-bottom: 30px;
        }
    }
}

.team-hover-card {
    width: 389px;
    height: 322px;
    margin-bottom: 0px;

    .overlay {
        //display: none;

        .title {
            display: none;
        }
    }

    .underlay {
        margin-left: 30px;

        .title {
            margin-bottom: 0px;
            width: 100%;
        }

        .description {
            margin-bottom: 26px;
        }

        button {
            margin-bottom: 30px;
        }

        .contact-container {
            display: none;
        }

        .quote {
            display: none;
        }

        .bio {
            display: none;
        }
    }
}

//
// Menu
//

.menu {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 0px;

    .btn-menu-container {
        height: 0px;
        position: fixed;
        max-width: 1450px; // help restrict where the btn-menu sits - just to the right of the main website content

        .btn-menu {
            position: absolute;
            right: 40px;
            top: 31px;
            width: 66px;
            height: 66px;
            background-color: #0167ff;
            border-radius: 100%;

            cursor: pointer;

            img {
                position: absolute;
            }
        }
    }

    button {
        color: $color-white;
        background-color: transparent;
        font-weight: bold;
        text-decoration: none;
        width: 100%;
        @include font-desktop-halyard-h-4;
        display: flex; //inline-block;
        flex-direction: row;
        height: auto;
        text-align: left;
        padding-top: 15px;
        padding-bottom: 15px;

        .label {
            flex: 1;
        }

        .arrow {
            display: none;
            transition: transform 0.5s ease-out;
        }
    }

    .dropdown {
        height: 330px;
        background-color: $color-dark-grey;
        transform: translateY(-100%);

        .container-wrapper {
            height: 100%;

            .container {
                display: flex;
                flex-direction: row;

                .btn-x-container {
                    margin-top: 53px;

                    .btn-x {
                        padding-left: 20px;
                        padding-top: 15px;
                        padding-bottom: 15px;
                        height: 36px;
                        box-sizing: content-box;
                        //cursor: pointer;
                        //padding-top: 25px;
                        //padding-left: 20px;
                        //display: flex;
                        //justify-items: center;
                        //padding-bottom: 15px;
                        //height: auto;
                    }

                    @media only screen and (max-width: 1000px) {
                        .btn-x {
                            padding-top: 10px;
                        }
                    }

                    @media only screen and (max-width: 750px) {
                        .btn-x {
                            padding-top: 7px;
                        }
                    }
                }
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        margin-top: 53px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //height: calc(100%-53px);
        flex: 1;

        li {
            display: inline;
            list-style-type: none;
            margin: 0;
            flex: 1;

            .button-container {
                display: flex;
                flex-direction: row;

                .btn-arrow {
                    display: none;
                    flex: 0;
                }

                .invalid {
                    cursor: auto;
                }

                @media only screen and (max-width: 1000px) {
                    button {
                        @include font-desktop-halyard-h-5;
                        padding-bottom: 10px;
                    }
                }

                @media only screen and (max-width: 750px) {
                    button {
                        @include font-desktop-halyard-h-6;
                        padding-bottom: 5px;
                    }
                }
            }

            // child-nav
            ul {
                max-height: auto;
                overflow: hidden;
                transition: max-height 0.5s ease-out;

                display: flex;
                flex-direction: column;
                padding-top: 0px;
                padding-left: 0px;
                margin-right: -50px; // allows this to extend into the next container to the right
                margin-top: 0px;

                li {
                    .button-container {
                        button {
                            @include font-desktop-halyard-h-6;
                            background-color: transparent;
                            //height: 29px;
                            padding-top: 2.5px;
                            padding-bottom: 2.5px;
                        }
                    }
                }
            }
        }
    }

    .active {
        text-decoration: underline;
    }

    .social {
        margin-top: 10px;

        .linkedin img,
        .instagram img,
        .facebook img {
            height: 30px;
            padding: 8px;
            object-fit: contain;
        }
    }

    .slide-enter {
        transform: translateY(-100%);
    }

    .slide-enter-active {
        transform: translateY(0%);
        transition: transform 300ms;
    }

    .slide-enter-done {
        transform: translateY(0%);
    }

    .slide-exit {
        transform: translateY(0%);
    }

    .slide-exit-active {
        transform: translateY(-100%);
        transition: transform 300ms;
    }

    .slide-exit-done {
        transform: translateY(-100%);
    }
}

.mobile {
    @import 'mobile';
}

.clients-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
    align-items: center;
    max-width: 100% !important;
}

.our-clients {
    max-width: 100% !important;
    margin: 80px auto;
    width: 100%;
}

.client-logo,
.client-logo-inner {
    margin-bottom: 0 !important;
    max-width: 100% !important;
}

.client-logo img {
    max-width: 160px;
    height: auto;
    position: relative;
    width: 100%;
}

@media (max-width: 1024px) {
    .clients-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .clients-grid {
        grid-template-columns: 1fr;
    }
}