@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.react-modal {
    position: fixed;
    //position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
    z-index: 101;
}
.react-modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 102;
    background-color: #333;
    opacity: 0.1;
}
.animate-modal-overlay-enter-done {
    opacity: 0.1;
}
.animate-modal-overlay-enter-active {
    display: block !important;
    opacity: 0.9;
    transition: all 500ms infinite;
}
.animate-modal-overlay-enter-done {
    opacity: 0.9;
}
.animate-modal-overlay-exit {
    opacity: 0.9;
}
.animate-modal-overlay-exit-active {
    display: block !important;
    opacity: 0.1;
    transition: all 500ms infinite;
}
.animate-modal-overlay-exit-done {
    opacity: 0;
}
.react-modal-body {
    position: relative;
    //background-color: #fff;
    border-radius: 4px;
    z-index: 103;
    //width: 400px;
    min-height: 200px;
    display: none;
}
.react-modal-body-close {
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
}
.react-modal-body-close:after,
.react-modal-body-close:before {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: #999;
    border-radius: 100%;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
}
.react-modal-body-close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.react-modal-body-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.fadeInUp-enter-done {
    display: block !important;
}
.fadeInUp-enter-active {
    display: block !important;
    -webkit-animation: fadeInUp 500ms infinite;
    animation: fadeInUp 500ms infinite;
}
.fadeInUp-exit-active {
    display: block !important;
    -webkit-animation: fadeOutUp 500ms infinite;
    animation: fadeOutUp 500ms infinite;
}
.fadeInUp-exit-done {
    display: none !important;
}
